<template>
  <Menu />

  <div id="invoiceApp" class="container-fluid">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      <strong>New Jewel</strong>
    </h6>
    <hr style="height: 0.5px; margin-bottom: 30px" />
    <form @submit.prevent="applyFilter()">
      <div>
        <div class="row justify-content-end">
          <div
            class="col-md-1"
            v-for="[currencyKey, currencyValue] in Object.entries(
              constantExchangeRates
            )"
          >
            <input
              type="number"
              step=".01"
              @keydown.enter.prevent
              v-model="constantExchangeRates[currencyKey]"
              class="form-control"
              style="width: 86px; height: 20px; font-size: 11px"
            />
            <small class="form-text text-muted">{{ currencyKey }} Rate</small>
          </div>
          <div class="col-md-1">
            <input
              type="number"
              step=".01"
              @keydown.enter.prevent
              id="firstPrice"
              v-model="priceCalculatorInput"
              class="form-control"
              style="width: 86px; height: 20px; font-size: 11px"
            />
          </div>
          <div class="col-md-1 align-items-center justify-content-end">
            <b>/ &nbsp; </b>
            <span>{{ priceCalculatorInput * 4.5 || 0 }}</span>
          </div>
          <div class="row justify-content-end" style="">
            <div class="col-md-2">
              <small class="form-text text-muted"
                >Capital calculator ( * 4,5)</small
              >
            </div>
          </div>
        </div>

        <form @submit.prevent="createJewel" id="mainProductForm">
          <div class="row justify-content-center">
            <div class="col-md-2">
              <label for="referenceNumber">Reference Number</label>
              <input
                type="text"
                id="referenceNumber"
                @keydown.enter.prevent
                @change="referenceController"
                v-model="productModel.reference_no"
                class="form-control input-sm"
                required
              />
            </div>
            <div class="col-md-2">
              <label for="modelNumber">Model Number</label>
              <input
                type="text"
                id="modelNumber"
                v-model="productModel.model_no"
                class="form-control"
              />
            </div>

            <div class="col-md-2">
              <label for="firstPrice"> Tag Price</label>
              <input
                type="number"
                step=".01"
                id="firstPrice"
                v-model="productModel.first_price"
                class="form-control"
              />
            </div>
            <div class="col-md-2">
              <!-- button that calculate list prices -->
              <button
                type="button"
                @click="calculateListPrices"
                class="btn btn-secondary mt-4"
              >
                Calculate List Prices
              </button>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-2">
              <label for="firstPrice"> List Price (EUR)</label>
              <input
                type="number"
                id="firstPrice"
                v-model="productModel.second_price"
                class="form-control"
              />
            </div>
            <div class="col-md-2">
              <label for="firstPrice"> List Price (USD)</label>
              <input
                type="number"
                id="firstPrice"
                v-model="productModel.third_price"
                class="form-control"
              />
            </div>
            <div class="col-md-2">
              <label for="firstPrice"> List Price (AED)</label>
              <input
                type="number"
                id="firstPrice"
                v-model="productModel.fourth_price"
                class="form-control"
              />
            </div>
            <div class="col-md-2"></div>
          </div>
          <!-- </div> -->
          <div class="row justify-content-center">
            <div class="col-md-2">
              <label for="type">Type</label>
              <select
                id="type"
                v-model="productModel.jewel[0].type"
                class="form-control"
                required
              >
                <option
                  v-for="t in jewelTypeModel"
                  :key="t.id"
                  v-bind:value="t.id"
                >
                  {{ t.name }}
                </option>
              </select>
            </div>

            <div class="col-md-2">
              <label for="productType">Product Type</label>
              <v-select
                :options="producTypeModel"
                v-model="productModel.jewel[0].product_type"
                id="productType"
                label="type_name"
                :multiple="true"
              />
              <!-- <MultiSelect id="productType"  v-model="productModel.jewel[0].product_type" :options="producTypeModel" :filter="true" optionLabel="type_name" /> -->
            </div>

            <div class="col-md-2">
              <label for="subType">Model</label>
              <!-- <MultiSelect id="subType"  v-model="productModel.jewel[0].subtypes" :options="subTypeModel" :filter="true" optionLabel="name" /> -->
              <v-select
                :options="subTypeModel"
                v-model="productModel.jewel[0].subtypes"
                id="subType"
                label="name"
                :multiple="true"
              />
              <!-- <p>{{productModel.jewel[0].subtypes}}</p> -->
            </div>

            <!-- <div class="col-md-4">
				<label for="tagsInput">Tags</label>
				<Chips id="tagsInput" v-model="productModel.tag" class="form-control p-fluid" style="height:60px; border-style: none;" />
			</div> -->

            <div class="col-md-2">
              <label for="productTag">Tags</label>
              <v-select
                :options="tagModel"
                v-model="productModel.tags"
                id="productTag"
                :reduce="(tag) => tagConverter(tag.id)"
                label="name"
                :multiple="true"
                :key="tagKey"
              />
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-2">
              <label for="goldKarat">Gold Karat</label>
              <!-- <input type="text"  step=".01"  id="goldKarat" v-model="productModel.jewel[0].gold_karat" class="form-control" > -->
              <select
                id="manufacturerType"
                v-model="productModel.jewel[0].karat"
                class="form-control"
              >
                <option :value="null" selected></option>
                <option v-for="k in karatModel" :key="k.id" v-bind:value="k.id">
                  {{ k.name }}
                </option>
              </select>
            </div>

            <div class="col-md-2">
              <label for="goldWeight">Gross Weight</label>
              <input
                type="number"
                step=".01"
                id="goldWeight"
                v-model="productModel.jewel[0].gold_weight"
                class="form-control"
              />
            </div>

            <div class="col-md-2">
              <label for="netWeight">Net Weight</label>
              <input
                type="number"
                step=".01"
                id="netWeight"
                v-model="productModel.jewel[0].net_weight"
                class="form-control"
              />
            </div>
            <div class="col-md-2 my-4">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="productModel.active"
                id="stock"
              />
              <label
                class="form-check-label"
                style="margin-left: 4px"
                for="stock"
                >Stock</label
              >
            </div>
            <!-- <div class="col-md-2">
				<label for="goldPrice">Gold Price</label>
				<input type="text"  step=".01" id="goldPrice" v-model="productModel.jewel[0].gold_price" class="form-control" >
			</div>	 -->
          </div>
          <div class="row justify-content-center">
            <div class="col-md-2">
              <label for="productionCode">Production Code</label>
              <input
                type="text"
                id="productionCode"
                v-model="productModel.production_code"
                class="form-control"
              />
            </div>

            <div class="col-md-2">
              <label for="ticketInfo">Ticket Info</label>
              <input
                type="text"
                id="ticketInfo"
                v-model="productModel.ticket_info"
                class="form-control"
              />
            </div>
            <div class="col-md-3">
              <label for="description">Description</label>
              <textarea
                type="text"
                id="description"
                v-model="productModel.description"
                class="form-control"
              ></textarea>
            </div>
            <div class="col-md-1"></div>
          </div>

          <div class="row justify-content-center mt-3">
            <!-- Manufacturer -->

            <div class="col-md-5">
              <div>
                <p class="text-left text-bold text-info text-dark">
                  Manufacturers
                  <small class="form-text text-muted"
                    >(If the manufacturer is empty, that line is
                    deleted!)</small
                  >
                </p>
                <!-- <button @click="manufShowClick" type="button" class="btn btn-outline-dark" style="font-size: 12px; padding: 6px 10px;margin-left:5px" >{{manufShowLabel}}</button> -->
              </div>
              <table id="manuf-table" class="table table-bordered">
                <thead>
                  <tr class="text-center">
                    <th>Type</th>
                    <th>Manufacturer</th>
                    <th>Price</th>
                    <th>
                      <button
                        type="button"
                        v-on:click="addItem"
                        class="btn btn-success btn-sm"
                      >
                        <span area-hidden="true">&plus;</span>
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(d, i) in productModel.manufacturers"
                    :key="i"
                    style
                  >
                    <td width="20%">
                      <div class="form-group">
                        <select
                          id="manufacturerType"
                          v-model="d.type"
                          class="form-control"
                        >
                          <option :value="null">-Select-</option>
                          <option
                            v-for="p in manufactureTypes"
                            :key="p.manufacturer_type_id"
                            v-bind:value="p.manufacturer_type_id"
                          >
                            {{ p.manufacturer_type }}
                          </option>
                        </select>
                      </div>
                    </td>
                    <td width="20%">
                      <div class="form-group">
                        <!-- <select id="products" v-model="d.manufacturer" class="form-control" >
							<option value="0">-Select-</option>
							<option v-for="p in manufacturers" :key="p.manufacturer_id" v-bind:value="p.manufacturer_id" >{{p.name_surname}}</option>
						</select> -->
                        <v-select
                          id="manufacturer"
                          :options="manufacturers"
                          label="firm_name"
                          v-model="d.manufacturer"
                          :reduce="
                            (manufacturer) => manufacturer.manufacturer_id
                          "
                        >
                        </v-select>
                      </div>
                    </td>
                    <td width="15%">
                      <div class="form-group">
                        <input
                          type="number"
                          step=".01"
                          id="Price"
                          v-model="d.price"
                          class="form-control"
                        />
                      </div>
                    </td>
                    <td width="10%">
                      <!-- <a href="">Delete</a> -->
                      <button
                        type="button"
                        v-on:click="removeItem(i)"
                        class="btn btn-danger btn-sm add-minus-button"
                        tabindex="80"
                      >
                        <span aria-hidden="true">&minus;</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Manufacturer END -->
            </div>

            <!-- Golds -->
            <div class="col-md-6">
              <div>
                <p class="text-left text-bold text-info text-dark">
                  Golds
                  <small class="form-text text-muted"
                    >(If the stone id is empty, that line is deleted!)</small
                  >
                </p>
                <!-- <button @click="goldShowClick" type="button" class="btn btn-outline-dark" style="font-size: 12px; padding: 6px 10px;margin-left:5px" >{{goldShowLabel}}</button> -->
              </div>

              <table id="manuf-table" class="table table-bordered">
                <thead>
                  <tr class="text-center">
                    <th>Stone Id</th>
                    <th>Code</th>
                    <th>Number</th>
                    <th>Weight</th>
                    <th>Price</th>

                    <th>
                      <button
                        type="button"
                        v-on:click="addGold"
                        class="btn btn-success btn-sm"
                      >
                        <span area-hidden="true">&plus;</span>
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(d, i) in productModel.jewel[0].jewel_stone"
                    :key="i"
                  >
                    <td width="10%">
                      <div class="form-group">
                        <!-- <input type="float" id="Stone" v-model="d.stone"  class="form-control"> -->
                        <SelectStone
                          :key="selectStoneKey"
                          :stone-model="stoneModel"
                          v-model="d.stone"
                          :selected="d.stone"
                        >
                        </SelectStone>
                      </div>
                    </td>
                    <td width="10%">
                      <div class="form-group">
                        <input
                          type="text"
                          id="Code"
                          v-model="d.code"
                          class="form-control"
                        />
                      </div>
                    </td>
                    <td width="10%">
                      <div class="form-group">
                        <input
                          type="number"
                          step="0.01"
                          id="stoneNumber"
                          v-model="d.number"
                          class="form-control"
                        />
                      </div>
                    </td>
                    <td width="10%">
                      <div class="form-group">
                        <input
                          style="font-size: 12px"
                          type="number"
                          step="0.01"
                          id="stoneWeight"
                          v-model="d.weight"
                          class="form-control"
                        />
                      </div>
                    </td>
                    <td width="10%">
                      <div class="form-group">
                        <input
                          style="font-size: 12px"
                          type="number"
                          step="0.01"
                          id="stonePrice"
                          v-model="d.price"
                          class="form-control"
                        />
                      </div>
                    </td>
                    <td width="10%">
                      <button
                        type="button"
                        v-on:click="removeGold(i)"
                        class="btn btn-danger btn-sm"
                        tabindex="99"
                      >
                        <span aria-hidden="true">&minus;</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Golds END -->
            </div>
          </div>

          <div>
            <div class="row justify-content-center mt-3">
              <div class="col-md-1">
                <div
                  v-if="submitLoading"
                  class="spinner-border text-primary spinner-border-lg"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
                <button
                  v-else
                  id=""
                  class="btn btn-outline-secondary"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
        <!-- <button id="form-submit" type="submit" >Submit</button> -->
      </div>
    </form>
  </div>

  <div class="modal" id="usedReference" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">This Reference Has Been Used Before</h5>
          <button
            type="button"
            class="btn-close"
            data-coreui-dismiss="modal"
            @click="closeUsedReference"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            This reference has been used before. Do you want to create a copy of
            this product?
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="closeUsedReference"
            data-coreui-dismiss="modal"
          >
            Close
          </button>
          <button type="button" class="btn btn-danger" @click="createCopy">
            Create Copy
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import { getAPI, postAPI } from "@/global/callAPI";
import SelectStone from "@/components/SelectStone.vue";
export default {
  name: "NewJewel",
  components: { Menu, SelectStone },
  data: function () {
    return {
      isSubmitOk: null,
      selected: null,
      options: ["list", "of", "options"],
      title: "New Jewel",
      thumbnail: null,
      tag: "",
      tags: null,
      tagModel: [],
      productModel: {
        reference_no: null,
        // store: null,
        description: null,
        first_price: null,
        second_price: null,
        description: null,
        active: true,
        new_product: true,
        model_no: null,
        ticket_info: null,
        production_code: null,
        tags: [],
        watch: [],
        jewel: [
          {
            type: 1,
            karat: null,
            gold_karat: null,
            gold_weight: null,
            net_weight: null,
            gold_price: null,
            subtype: null,
            product_type: [],
            jewel_stone: [
              { code: null, number: null, weight: null, stone: null },
              { code: null, number: null, weight: null, stone: null },
              { code: null, number: null, weight: null, stone: null },
              { code: null, number: null, weight: null, stone: null },
              { code: null, number: null, weight: null, stone: null },
            ],
            subtypes: [],
          },
        ],
        manufacturers: [
          { manufacturer: null, type: null, price: null },
          { manufacturer: null, type: null, price: null },
          { manufacturer: null, type: null, price: null },
          { manufacturer: null, type: null, price: null },
          { manufacturer: null, type: null, price: null },
        ],
      },
      storeModel: null,
      manufShowLabel: "Add",
      goldShowLabel: "Add",
      manufShow: false,
      goldShow: false,
      manufacturers: [],
      producTypeModel: [],
      manufactureTypes: [],
      subTypeModel: [],
      selectedSubtypes: null,
      stoneModel: [],
      payload: null,
      selectStoneKey: 0,
      tagKey: 0,
      copyReference: null,
      priceCalculatorInput: 0,
      karatModel: [],
      submitLoading: false,
      jewelTypeModel: [],
      constantExchangeRates: {
        EUR: 0.82,
        USD: 0.85,
        AED: 2.9,
      },
    };
  },
  created: function () {
    // getAPI('stores').then((res) => {this.storeModel = res.data.results});
    getAPI("manufacturers?ordering=firm_name&limit=10000").then((res) => {
      this.manufacturers = res.data.results;
    });
    getAPI("product-types?limit=10000&ordering=type_name&").then((res) => {
      this.producTypeModel = res.data.results;
    });
    getAPI("manufacturer-types?limit=10000&ordering=manufacturer_type&").then(
      (res) => {
        this.manufactureTypes = res.data.results;
      }
    );
    getAPI("jewel-subtypes?limit=10000&ordering=name&").then((res) => {
      this.subTypeModel = res.data.results;
    });
    getAPI("product-tags?limit=10000&ordering=name&").then((res) => {
      this.tagModel = res.data.results;
    });
    getAPI("gold-karats?limit=100000").then((res) => {
      this.karatModel = res.data.results;
    });
    getAPI("jewel-types?limit=100000").then((res) => {
      this.jewelTypeModel = res.data.results;
    });
  },

  methods: {
    tagConverter(id) {
      return { tag: id };
    },
    // Manufacture methods
    manufShowClick: function () {
      this.manufShow = !this.manufShow;
      if (this.manufShowLabel === "Show") {
        this.manufShowLabel = "Hide";
      } else {
        this.manufShowLabel = "Show";
      }
    },
    removeItem: function (id) {
      this.productModel.manufacturers.splice(id, 1);
    },
    addItem: function (e) {
      e.preventDefault();
      let detail = {
        manufacturer: null,
        type: null,
        price: null,
      };
      this.productModel.manufacturers.push(detail);
    },

    // Gold Methods
    goldShowClick: function () {
      this.goldShow = !this.goldShow;
      if (this.goldShowLabel === "Show") {
        this.goldShowLabel = "Hide";
      } else {
        this.goldShowLabel = "Show";
      }
    },
    removeGold: function (id) {
      this.productModel.jewel[0].jewel_stone.splice(id, 1);
    },
    addGold: function (e) {
      e.preventDefault();
      let detail = {
        code: null,
        number: null,
        weight: null,
        stone: null,
      };
      this.productModel.jewel[0].jewel_stone.push(detail);
    },
    // Form methods
    createJewel() {
      Promise.resolve(this.referenceController).then(this.submitProcess);
    },

    submitProcess() {
      this.submitLoading = true;
      console.log(this.isSubmitOk);
      if (this.isSubmitOk === true) {
        // delete blank manufacturers
        var man = this.productModel.manufacturers;
        var i;
        for (i = 0; i < man.length; i += 1) {
          if (man[i].manufacturer === null || man[i].manufacturer === "") {
            man.splice(i, 1);
            i -= 1;
          }
        }
        // delete blank stones
        var sto = this.productModel.jewel[0].jewel_stone;
        var i;

        for (i = 0; i < sto.length; i += 1) {
          const isEmpty = Object.values(sto[i]).every(
            (x) => x === null || x === ""
          );
          if (isEmpty === true) {
            sto.splice(i, 1);
            i -= 1;
          }
        }

        const payload = JSON.parse(JSON.stringify(this.productModel));
        console.log(payload);
        payload["first_price"] = payload["first_price"] || null;
        payload["second_price"] = payload["second_price"] || null;

        payload["jewel"][0]["gold_karat"] =
          payload["jewel"][0]["gold_karat"] || null;
        payload["jewel"][0]["karat"] = payload["jewel"][0]["karat"] || null;
        payload["jewel"][0]["gold_price"] =
          payload["jewel"][0]["gold_price"] || null;
        payload["jewel"][0]["gold_weight"] =
          payload["jewel"][0]["gold_weight"] || null;
        payload["jewel"][0]["net_weight"] =
          payload["jewel"][0]["net_weight"] || null;

        // payload = payload.concat(this.productModel)
        // payload = Object.assign(payload, this.productModel)

        // if (payload.tag !== null ) {
        // payload.tag = payload.tag.toString();};

        if (payload.jewel[0].subtypes !== null) {
          payload.jewel[0].subtypes = payload.jewel[0].subtypes.map((item) => ({
            subtype: item.id,
          }));
        }

        if (payload.jewel[0].product_type !== null) {
          payload.jewel[0].product_type = payload.jewel[0].product_type.map(
            (item) => ({ product_type: item.id })
          );
        }

        postAPI("products", payload)
          .then()
          .then((res) => {
            if (res.status === 201) {
              Toast2.fire({
                icon: "success",
                title: `${payload["reference_no"]} created successfully.`,
              });
              this.productModel["reference_no"] = null;
            } else {
              Toast2.fire({
                icon: "danger",
                title: `${payload["reference_no"]} couldn't be created. Error ${res.status}`,
              });
            }

            console.log(res);

            // this.$router.push('/products/'+res.data.product_id)
          })
          .finally(() => {
            this.submitLoading = false;
          });
      }
    },
    referenceController() {
      this.copyReference = this.productModel["reference_no"];
      this.isSubmitOk = null;
      getAPI(
        "product-search?reference_no=" + this.productModel.reference_no
      ).then((res) => {
        if (res.data.results.length != 0) {
          this.isSubmitOk = false;

          this.openUsedReference();
          return true;
          // alert("This reference used by another product!")
        } else {
          this.isSubmitOk = true;
          return false;
        }
      });
    },
    openUsedReference() {
      jQuery("#usedReference").modal("show");
      this.productModel["reference_no"] = null;

      // this.productModel.reference_no = null
    },
    closeUsedReference() {
      jQuery("#usedReference").modal("hide");
      this.productModel["reference_no"] = null;
    },

    createCopy() {
      const reference = this.copyReference;
      getAPI("products?reference_no=" + reference).then((res) => {
        if (res.data.results[0]) {
          console.log(res.data.results[0]);

          let model = {};
          const data = res.data.results[0];

          console.log(data.watch.length);
          if (data.jewel.length === 0) {
            window.alert("This product is not a jewel.");
            this.productModel.reference_no = null;
            jQuery("#usedReference").modal("hide");
            return false;
          }

          model["description"] = data.description || null;
          model["first_price"] = data.first_price || null;
          model["second_price"] = data.second_price || null;
          model["model_no"] = data.model_no || null;
          model["ticket_info"] = data.ticket_info || null;
          model["production_code"] = data.production_code || null;
          model["watch"] = data.watch || [];
          model["jewel"] = [{}];

          model["active"] = true;

          model["jewel"][0]["gold_karat"] = data["jewel"][0]["gold_karat"];
          model["jewel"][0]["karat"] = data["jewel"][0]["karat"];

          model["jewel"][0]["gold_weight"] = data["jewel"][0]["gold_weight"];
          model["jewel"][0]["net_weight"] = data["jewel"][0]["net_weight"];

          model["jewel"][0]["gold_price"] = data["jewel"][0]["gold_price"];

          model["jewel"][0]["jewel_stone"] = [];

          data["jewel"][0]["jewel_stone"].forEach((element, index) => {
            console.log(element);
            model["jewel"][0]["jewel_stone"][index] = {};
            model["jewel"][0]["jewel_stone"][index]["code"] = element.code;
            model["jewel"][0]["jewel_stone"][index]["number"] = element.number;
            model["jewel"][0]["jewel_stone"][index]["weight"] = element.weight;
            model["jewel"][0]["jewel_stone"][index]["stone"] = element.stone;
          });

          model["manufacturers"] = [];
          data["manufacturers"].forEach((element, index) => {
            model["manufacturers"][index] = {};
            model["manufacturers"][index]["manufacturer"] =
              element.manufacturer;
            model["manufacturers"][index]["type"] = element.type;
            model["manufacturers"][index]["price"] = element.price;
          });

          model["tags"] = [];

          data["tags"].forEach((element, index) => {
            model["tags"][index] = {};
            model["tags"][index]["tag"] = element.tag;
          });

          model["jewel"][0]["product_type"] = [];

          data["jewel"][0]["product_type"].forEach((element, index) => {
            model["jewel"][0]["product_type"][index] = {};
            model["jewel"][0]["product_type"][index]["type_name"] =
              element.name;
            model["jewel"][0]["product_type"][index]["id"] =
              element.product_type;
          });

          model["jewel"][0]["subtypes"] = [];
          data["jewel"][0]["subtypes"].forEach((element, index) => {
            model["jewel"][0]["subtypes"][index] = {};
            model["jewel"][0]["subtypes"][index]["name"] = element.name;
            model["jewel"][0]["subtypes"][index]["id"] = element.subtype;
          });

          this.selectStoneKey += 1;
          this.tagKey += 1;

          this.productModel = model;
          this.closeUsedReference();
        }
      });
    },
    calculateListPrices() {
      // Extract necessary properties for clarity
      const { first_price } = this.productModel;

      // Define a helper function to round up to the nearest 50
      const roundUpToNearestFifty = (value) => {
        // Divide by 50, round up to the nearest integer, then multiply by 50
        return Math.ceil(value / 50) * 50;
      };

      // Define a function to calculate and round the price
      const calculateAndRoundPrice = (rate) => {
        // Calculate the price
        const rawPrice = first_price * rate;
        // Round up to nearest 50
        return roundUpToNearestFifty(rawPrice);
      };

      // Map of currencies to their respective model properties
      const currencyMapping = {
        USD: "third_price",
        EUR: "second_price",
        AED: "fourth_price",
      };

      // Iterate over the currency mapping and update prices
      Object.keys(currencyMapping).forEach((currency) => {
        const property = currencyMapping[currency];
        this.productModel[property] = calculateAndRoundPrice(
          this.constantExchangeRates[currency]
        );
      });
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  margin: 0 auto;
  padding: 9px;
  color: #fff;
}
.header h1 {
  font-size: 3.3em;
}
.text-bold {
  font-weight: 500;
}
.total-underline {
  border-bottom: 3px solid black;
  float: right;
}

hr {
  border-top: 1px solid black;
}

.btn.btn-danger.btn-sm {
  display: block;
  margin: auto;
}

#form-submit {
  background-color: black;
  width: 120px;
  margin-left: 240 px;
  margin-right: auto;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  position: absolute;
  margin-top: 20px;
  left: 50%;
}
#invoice-table {
  background-color: white !important;
}
.row justify-content-center {
  margin-top: 20px;
}

#manuf-table {
  /* width:40%; */
  margin-top: 10px;
}

#mainProductForm {
  margin-left: 2%;
}

div.vs__dropdown-toggle {
  border-radius: 0px;
}
.p-multiselect {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  height: 60%;
  border-radius: 0px;
}
input {
  border-radius: 0px;
}

select {
  border-radius: 0px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* .add-minus-button {

} */
</style>
